import Grid from "@material-ui/core/Grid";
import backgroundImg from "../assets/backgroundImg.jpeg";
import Typography from "@material-ui/core/Typography";
import React from "react";
import useStyles from "../styles/StyledComponents";
import PropTypes from "prop-types";

Info.propTypes = {
    ticketPrice: PropTypes.number
}
export default function Info(props) {
    let { img, hr, boldFont } = useStyles();
    let { ticketPrice } = props;

    return (
        <React.Fragment>
            <Grid item>
                <img className={img} src={backgroundImg} alt='Fall Charity Golf Classic logo' />
                <hr className={hr} />
            </Grid>
            <Grid item>
                <Typography variant='body1'>We are excited to announce the 24th Annual Fall Charity Golf Classic at Musket Ridge Golf Club which will be held on <strong>Friday, August 22, 2025</strong>.</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1' className={boldFont}>Proceeds from this event will benefit Mission 10-42, Friends of the Child Advocacy Center, Pathfinders for Autism, and Steadfast, four local charities that we proudly support.</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>Registrations are ${ticketPrice} per person for the A.M. or P.M. flight. It will be first come first serve for the A.M. flight, as it will fill up quickly. </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>Your day of golf will be complimented with a delicious catered lunch, an all-day supply of grilled hamburgers and hot dogs, water, and soda. Please come out and enjoy a day of golf, prizes and friends. We look forward to seeing you there and helping these meaningful causes.</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>Sincerely,</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'><em>Nick Constantine, Tournament Chairman</em></Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'><em>Chase Hoffman, Tournament Co-Chairman</em></Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'><em>Anthony Reggio, Tournament Co-Chairman</em></Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>For more information, contact us at <a href='mailto:mdfop102golf@gmail.com' target='_blank' rel='noreferrer noopener'>mdfop102golf@gmail.com </a> or <a href='https://www.facebook.com/Frederick-County-Fraternal-Order-of-Police-102-622384904909941/' target='_blank' rel='noreferrer noopener'>Frederick County Fraternal Order of Police 102 on Facebook.</a></Typography>
                <hr className={hr} />
            </Grid>
        </React.Fragment>
    )
}